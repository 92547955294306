import {useEffect, useState} from 'react';
import {RemoteStore, ExtensionName} from '@s1/extensions';
import {extensions} from '@s1/dpl';
import {GAClientId} from './useLogging';

type RemoteConfigParams = Partial<extensions.BaseExtensionParams>;

export const useRemoteConfig = (extName: ExtensionName) => {
    const [remoteState, setRemoteState] = useState<RemoteConfigParams>();
    useEffect(() => {
        const sendData = async () => {
            if (remoteState !== undefined) {
                const clientId = await GAClientId;
                RemoteStore(clientId, extName).set(remoteState);
            }
        };
        sendData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extName, JSON.stringify(remoteState)]);
    return {remoteState, setRemoteState};
};
