import {css, Global} from '@emotion/core';
import {localStore, URLMarketingParams} from '@s1/extensions';
import React, {FC, PropsWithChildren, useEffect} from 'react';
import {useLocation} from '@reach/router';
import {getExtensionName} from '../../../common/ extensionMap';
import {useExperiment} from '../../../common/experiments/experimentContext';
import {useRemoteConfig} from '../../../common/hooks/RemoteConfig';
import {useChannel} from '../../../common/hooks/useChannel';
import {globalStyles} from '../constants';
// @ts-expect-error
import {logDpl, EVENTS} from '../util/dpl';

const store = localStore();

const MarketingLayout: FC<PropsWithChildren<{}>> = ({children}) => {
    const {experimentId} = useExperiment();
    const location = useLocation();
    const channel = useChannel();
    const extensionName = getExtensionName(location.pathname);
    if (extensionName === '') {
        throw Error('This is not a valid marketing page. Is the path in the extension name mapping?');
    }

    const {setRemoteState} = useRemoteConfig(extensionName);

    useEffect(() => {
        logDpl(EVENTS.MapquestExtensionLandingPageVisit, {experimentId}).then(() => {
            const payload = {...store.get(), ...URLMarketingParams(), channel};
            store.set(payload);
            setRemoteState(payload);
        });
    }, [channel, experimentId, setRemoteState]);

    return (
        <>
            <Global styles={globalStyles} />
            <main
                css={css`
                    height: 100%;
                `}
            >
                {children}
            </main>
        </>
    );
};

export default MarketingLayout;
