import {useState} from 'react';

// @ts-expect-error
import {logDpl, EVENTS} from '../util/dpl';

import {useExperiment} from '../../../common/experiments/experimentContext';
import {useLogging} from '../../../common/hooks/useLogging';

type CTAProps = {
    webStoreLink: string;
    isPrimary: boolean;
};

export const useCTA = ({webStoreLink, isPrimary}: CTAProps) => {
    const [isModalOverlayVisible, setIsModalOverlayVisible] = useState(false);
    const [hasFiredCtaClick, setHasFiredCtaClick] = useState(false);
    const {ClickEvent} = useLogging();
    const {experimentId} = useExperiment();

    const openWindow = () => {
        window.open(webStoreLink, 'DownloadMapquestDirections', 'resizable=1,top=0,left=0');
    };

    const initToggleWindowAndOverlay = () => {
        setTimeout(() => {
            setIsModalOverlayVisible(false);
            openWindow();
            setHasFiredCtaClick(false);
        }, 2500);
    };

    const onCtaClick = () => {
        if (!hasFiredCtaClick) {
            ClickEvent(
                {
                    page: isPrimary ? 'landing-page' : 'follow-on-offer',
                    type: 'cta-click'
                },
                {
                    channel: isPrimary ? '1' : '2'
                }
            ).then(() => {
                logDpl(EVENTS.MapquestExtensionCtaClick, {experimentId}).finally(() => {
                    setHasFiredCtaClick(true);
                    setIsModalOverlayVisible(true);
                    initToggleWindowAndOverlay();
                });
            });
        }
    };

    return {onCtaClick, isModalOverlayVisible};
};
