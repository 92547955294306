/* @noflow */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {css} from '@emotion/core';

const ModalOverlay = ({children, maxWidth, isVisible}) => {
    return (
        <aside
            className="modal-overlay"
            css={css`
                align-items: center;
                background-color: rgba(0, 0, 0, 0.75);
                display: ${isVisible ? 'flex' : 'none'};
                height: 100%;
                justify-content: center;
                left: 0;
                padding: 1rem;
                position: absolute;
                text-align: center;
                top: 0;
                width: 100%;
            `}
        >
            <div
                css={css`
                    align-items: center;
                    background: #fff;
                    border-radius: 8px;
                    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.11);
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    max-width: ${maxWidth}px;
                    padding: 2rem 3rem 1rem;
                    width: 100%;

                    @media (max-width: 600px) {
                        padding: 2rem 1rem 1rem;
                    }
                `}
            >
                {children}
            </div>
        </aside>
    );
};

ModalOverlay.propTypes = {
    children: PropTypes.node.isRequired,
    maxWidth: PropTypes.string,
    isVisible: PropTypes.bool
};

ModalOverlay.defaultProps = {
    maxWidth: '650',
    isVisible: false
};

export default ModalOverlay;
